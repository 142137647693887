<template>
  <div :class="classes">
    {{ label }}
  </div>
</template>
<script>
export default {
  name: 'hey-listen-control',
  computed: {
    classes () {
      if (this.class) {
        return 'hey-listen-control ' + this.class
      }
      return 'hey-listen-control'
    }
  },
  data () {
    return {
    }
  },
  props: [
    'class',
    'label'
  ]
}
</script>
