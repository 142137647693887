<template>
  <div :class="dataTableClass">
    <div class="d-flex flex-wrap justify-content-between align-items-center title-row">
      <div>
        <slot name="title">
          <h5 class="dt-title" v-if="title">
            <fa icon="up-right-and-down-left-from-center" class="dt-header-icon" v-if="expandable && !isExpanded" @click="toggleExpanded"/>
            <fa icon="down-left-and-up-right-to-center" class="dt-header-icon dt-header-icon-active" v-else-if="expandable" @click="toggleExpanded"/>
            {{ title }}
          </h5>
        </slot>
      </div>
      <div v-if="!isExpanded" class="d-flex align-items-center">
        <slot name="filter-pillbox">
        </slot>
        <slot name="action-icon">
          <button v-if="actionFunc" @click="actionFunc()" class="btn btn-table-header" style="margin-top:2px;margin-right:2px;">
            <fa :icon="icon" class="button-icon-wrapped" style="margin-top:-2px;margin-right: 3px;" />
            <span v-if="actionLabel">
              {{ actionLabel }}
            </span>
            <span v-else>
              New<span v-if="itemType">&nbsp;{{ itemType }}</span>
            </span>
          </button>
          <router-link v-if="routerLink" :to="{ name: routerLink, params: routeParams }" class="btn btn-table-header py-0" style="margin-top:2px;margin-right:2px;">
            <fa :icon="icon" class="button-icon-wrapped" style="margin-top:-2px;margin-right: 3px;" />
            <span v-if="actionLabel">
              {{ actionLabel }}
            </span>
            <span v-else>
              New<span v-if="itemType">&nbsp;{{ itemType }}</span>
            </span>
          </router-link>
        </slot>
      </div>
    </div>
    <div class="p-2" v-if="!sortedRows || sortedRows.length < 1">
      <span v-if="loading">Loading ...</span>
      <span v-else>{{ sNoDataMessage }}</span>
    </div>
    <div v-else>
      <table>
        <thead>
          <tr class="dt-header" v-if="cols && visibleCols.length > 0">
            <th v-for="(col, i) in visibleCols" :key="i" :class="columnClass(col)" :style="col.style" @click="setSort(col.sort)" :title="col.title" >
              <div>
                <span v-if="col.icon" class="me-1">
                  <fa :icon="col.icon" class="dt-icon" />
                </span>
                <span v-if="col.text">
                  {{ col.text }}
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!sortedRows || sortedRows.length < 1"><td :colspan="cols.length">{{ sNoDataMessage }}</td></tr>
          <tr v-for="(row, j) in sortedRows" :key="j">
            <td v-for="(cell, k) in row" :key="k" :class="cell.class" :style="cell.style">
              <span v-if="cell.highlightedPrefix" class="me-2 notable">
                {{ cell.highlightedPrefix }}
              </span>
              <span v-if="cell.leftIcon" :class="cell.leftIconContainerClass">
                  <fa :icon="cell.leftIcon" class="button-icon-table" :style="cell.leftIconStyle"/>
              </span>
              <router-link class="padded" v-if="cell.route" :to="{ name: cell.route, params: cell.params }" :class="{normal: !cell.isWarning, warning: cell.isWarning}">
                {{ cell.value }}
              </router-link>
              <span v-else-if="cell.extLink">
                <a class="padded" :href="cell.extLink" target="__new_ext_">
                  <fa icon="up-right-from-square" v-if="cell.extLink" class="icon-text-link" />
                </a>
              </span>
              <span v-else-if="cell.checkbox">
                <fa icon="check" v-if="cell.value" class="dt-icon" />
              </span>
              <span v-else-if="cell.click && cell.i && !cell.selectOptions">
                <a class="padded" @click="cell.click(cell.i)" :class="{normal: !cell.isWarning, warning: cell.isWarning}">
                  <fa v-if="cell.icon" :icon="cell.icon" class="button-icon button-icon-table" />
                  <span :class="{normal: !cell.isWarning, warning: cell.isWarning}" v-else>
                    {{ cell.value }}
                  </span>
                </a>
              </span>
              <span v-else-if="cell.click && !cell.selectOptions">
                <a class="padded" @click="cell.click()" :class="{normal: !cell.isWarning, warning: cell.isWarning}">
                  <fa v-if="cell.icon" :icon="cell.icon" class="button-icon button-icon-table" />
                  <span :class="{normal: !cell.isWarning, warning: cell.isWarning}" v-else>
                    {{ cell.value }}
                  </span>
                </a>
              </span>
              <select v-else-if="cell.selectOptions" :value="cell.value" @change="cell.click(cell.i, $event.target.value)" class="form-select">
                <option v-for="opt in cell.selectOptions" :key="opt.value" :value="opt.value">
                  {{ opt.text }}
                </option>
              </select>
              <span v-else>{{ cell.value }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'cols', 'rows', 'noDataMessage', 'loading', 'loadingMessage', 'actionFunc', 'itemType', 'actionIcon', 'actionLabel', 'routerLink', 'routeParams', 'initialSort', 'initialSortReverse', 'expandable'],
  data () {
    return {
      sort: this.initialSort,
      reverseSort: this.initialSortReverse,
      isExpanded: false
    }
  },
  computed: {
    dataTableClass () {
      if (this.isExpanded) {
        return 'dt dt-wrapper dt-expanded'
      }
      return 'dt dt-wrapper'
    },
    visibleCols () {
      const sr = []
      for (const col of this.cols) {
        if (!this.isExpanded && col.expandedOnly) {
          continue
        }
        sr.push(col)
      }
      return sr
    },
    sortedRows () {
      if (!this.rows) {
        return null
      }
      if (!this.sort && this.sort !== 0) {
        return this.rows
      }
      const skipCols = {}
      if (!this.isExpanded) {
        for (const i in this.cols) {
          if (this.cols[i].expandedOnly) {
            skipCols[i] = true
          }
        }
      }
      let sr = Array.from(this.rows)
      sr.sort(this.sortRows)
      if (this.reverseSort) {
        sr = sr.reverse()
      }
      const sr2 = []
      for (const i in sr) {
        const arr = []
        for (const j in sr[i]) {
          if (j in skipCols) {
            continue
          }
          arr.push(sr[i][j])
        }
        sr2.push(arr)
      }
      return sr2
    },
    icon () {
      if (this.actionIcon) {
        return this.actionIcon
      }
      return 'plus'
    },
    sNoDataMessage () {
      if (this.loading) {
        if (this.loadingMessage) {
          return this.loadingMessage
        }
        return 'Loading ...'
      }
      if (this.noDataMessage) {
        return this.noDataMessage
      }
      return 'No data'
    }
  },
  methods: {
    handleKeyPress (e) {
      if (this.isExpanded && e.code === 'Escape') {
        this.toggleExpanded()
      }
    },
    toggleExpanded () {
      this.isExpanded = !this.isExpanded
      if (this.isExpanded) {
        document.addEventListener('keydown', this.handleKeyPress)
      } else {
        document.removeEventListener('keydown', this.handleKeyPress)
      }
    },
    setSort (sort) {
      if (!(sort >= 0)) {
        return
      }
      if (sort === this.sort) {
        this.reverseSort = !this.reverseSort
      } else {
        this.sort = sort
        this.reverseSort = false
      }
    },
    columnClass (col) {
      let classes = col.class ? col.class : ''
      if (col.sort >= 0) {
        classes += ' clickable sortable'
        if (col.sort === this.sort) {
          classes += ' sorted'
          classes = this.reverseSort ? classes + ' sorted-desc' : classes + ' sorted-asc'
        }
      }
      return classes
    },
    sortRows (a, b) {
      const a1 = a[this.sort].sortValue ? a[this.sort].sortValue : a[this.sort].value
      const b1 = b[this.sort].sortValue ? b[this.sort].sortValue : b[this.sort].value

      if (a1 === undefined || a1 === null) {
        if (b1 === undefined || b1 === null) {
          return 0
        }
        return -1
      }
      if (b1 === undefined || b1 === null) {
        return 1
      }
      if (a1 === b1) {
        return 0
      } else {
        return (a1 < b1) ? -1 : 1
      }
    }
  },
  unmounted () {
    if (this.isExpanded) {
      document.removeEventListener('keydown', this.handleKeyPress)
    }
  }
}
</script>
