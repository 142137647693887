<template>
  <div>
    <ModalAddUpdateNode ref="nodeadd" :site="site" @reloadSite="reloadSite" />
    <ModalAddUpdateNode ref="nodeedit" :site="site" :node="nodeData" @reloadSite="reloadSite" :deviceList="deviceList" />
    <DataTable
      class="dt-info-pane-sm"
      :title="listTitle"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      :actionFunc="showAddPane"
      actionIcon="plus"
      itemType="Node"
      noDataMessage="No Nodes"
      :initialSort="0"
    >
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import ModalAddUpdateNode from '@/components/fleet/ModalAddUpdateNode.vue'
import BaseComponent from '@/components/BaseComponent.vue'

export default {
  name: 'site-nodes-list',
  extends: BaseComponent,
  data () {
    return {
      cols: [
        { text: 'Name', sort: 0 },
        { text: 'Crop', sort: 1 },
        { text: 'GPS', sort: 2, class: 'dt-centered' }
      ],
      entityTypeName: this.entityType,
      nodeData: null
    }
  },
  props: ['site', 'nodes', 'nodeColors', 'loading', 'nodeID', 'deviceList'],
  watch: {
    nodes: {
      handler () {
        this.checkDeepLink()
      }
    }
  },
  computed: {
    siteID () {
      if (!this.site || !this.site.id) {
        return ''
      }
      return this.site.id
    },
    listTitle () {
      return 'Nodes for this ' +
        this.siteClassification.charAt(0).toUpperCase() + this.siteClassification.slice(1)
    },
    siteClassification () {
      if (this.site && this.site.siteClassification) {
        return this.site.siteClassification
      }
      return 'site'
    },
    rows () {
      const out = []
      if (this.nodes) {
        let i = 0
        for (const key in this.nodes) {
          const node = this.nodes[key]
          out.push([
            { value: node.displayName || '< No Display Name >', click: this.showEditPane, i: node, class: { inactive: node.status === 'inactive' }, leftIcon: 'map-pin', leftIconContainerClass: 'gray-badge-background me-2', leftIconStyle: `color: ${this.nodeColors[key] || '#cccccc'}dd`, isWarning: !node.displayName },
            { value: this.cropFromNode(node) },
            { value: this.formatHasGPS(node), leftIcon: this.formatHasGPSIcon(node), class: { 'dt-centered': true, 'status-danger': !this.formatHasGPS(node) } }
          ])
          i++
        }
      }
      return out
    }
  },
  methods: {
    showNodeEditorById (id) {
      if (!id) {
        return
      }
      for (const node of this.nodes) {
        if (node.id === id) {
          this.showEditPane(node)
          break
        }
      }
    },
    checkDeepLink () {
      if (this.nodeID && this.nodes) {
        for (const key in this.nodes) {
          if (this.nodes[key].id === this.nodeID) {
            this.showEditPane(this.nodes[key])
            break
          }
        }
      }
    },
    cropFromNode (node) {
      if (!node.crops || node.crops.length === 0) {
        return '--'
      }
      return node.crops[0]
    },
    formatHasGPSIcon (elem) {
      if (elem && elem.lat && elem.long) {
        return ''
      }
      return 'exclamation'
    },
    formatHasGPS (elem) {
      if (elem && elem.lat && elem.long) {
        return '✓'
      }
      return ''
    },
    getMapLink (node) {
      if (node.lat && node.lat) {
        return 'https://www.google.com/maps/search/?api=1&query=' + node.lat + '%2c' + node.long
      }
      return null
    },
    showAddPane () {
      this.$refs.nodeadd.show()
    },
    showEditPane (node) {
      this.nodeData = node
      this.$nextTick(() => {
        this.$refs.nodeedit.show()
      })
    },
    formatElevation (input) {
      if (!input) {
        return '--'
      }
      return `${input}m`
    },
    reloadSite () {
      this.$emit('reloadSite')
    }
  },
  mounted () {
    Reloadables.registerReloadable('nodes-by-site-list', this.reloadSite)
    this.$nextTick(() => {
      this.checkDeepLink()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('nodes-by-site-list')
  },
  components: { DataTable, ModalAddUpdateNode }
}
</script>
