<template>
  <div>
    <DataTable
      class="dt-info-pane-sm"
      title="Recent Devices on this Gateway"
      :cols="cols"
      :rows="rows"
      :loading="loading"
      noDataMessage="No Devices"
      :initialSort="1"
      :expandable="true"
    >
    </DataTable>
  </div>
</template>

<script>
import BaseDeviceComponent from '@/components/fleet/BaseDeviceComponent.vue'
import DataTable from '@/components/tables/DataTable.vue'
import Reloadables from '@/services/reloadables'
import FleetDataService from '@/services/fleet/FleetDataService'

export default {
  name: 'devices-by-gateway-list',
  extends: BaseDeviceComponent,
  data () {
    return {
      listFilter: 'recent',
      cols: [
        { text: 'Device EUI', sort: 0 },
        { icon: 'clock', sort: 1 },
        { text: 'Site', sort: 2 },
        { text: 'Node', sort: 3 },
        { text: 'SNR', sort: 4, expandedOnly: true },
        { text: 'RSSI', sort: 5, expandedOnly: true }
      ],
      loading: false
    }
  },
  props: ['siteData'],
  computed: {
    rows () {
      const out = []
      if (this.activeDevices && this.activeDevices.length > 0) {
        for (const device of this.activeDevices) {
          out.push([
            {
              value: device.id,
              route: 'device-details',
              params: { id: device.id },
              class: { inactive: device.status === 'inactive' },
              isWarning: device.reportingStatus && device.reportingStatus === 'warning'
            },
            {
              value: this.getSince(device.report_time),
              class: {
                notable: !device.monitoringMuted && this.isTimeNotable(device.report_time),
                'status-error': !device.monitoringMuted && this.isTimeAlertable(device.report_time, device.model),
                'status-caution': !device.monitoringMuted && this.isTimeCautionable(device.report_time, device.model)
              },
              sortValue: Date.now() - device.report_time
            },
            { value: FleetDataService.getSiteDisplayName(device.site) },
            { value: FleetDataService.getNodeDisplayName(device.node) },
            { value: device.snr, class: this.snrClass(device.snr) },
            { value: device.rssi, class: this.rssiClass(device.rssi) }
          ])
        }
      }
      return out
    }
  },
  methods: {
    snrClass (input) {
      if (input > 0) {
        return 'status-ok'
      }
      if (input <= -5) {
        return 'status-warning'
      }
      return ''
    },
    rssiClass (input) {
      if (input < -125) {
        return 'status-danger'
      }
      if (input < -110) {
        return 'status-warning'
      }
      if (input >= -95) {
        return 'status-ok'
      }
      return ''
    },
    retrieveDevices (id) {
      this.loading = true
      FleetDataService.getDevicesByGateway(id)
        .then(response => {
          this.devices = response.data
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    refreshList () {
      this.retrieveDevices(this.$route.params.id)
    }
  },
  mounted () {
    Reloadables.registerReloadable('devices-by-gw-list', this.refreshList)
    this.$nextTick(() => {
      this.refreshList()
    })
  },
  unmounted () {
    Reloadables.unregisterReloadable('devices-by-gw-list')
  },
  components: { DataTable }
}
</script>
