<template>
  <div class="btn-group pillbox" role="group" :aria-label="label">
    <template v-for="pill of pills" :key="pill">
      <input type="radio" class="btn-check" :value="pillValue(pill)" :name="groupName"
        :id="pillId(pill)" v-model="activePill" autocomplete="off" @click="pillClicked" />
      <label class="btn btn-outline-primary" :for="pillId(pill)">&nbsp;{{ pillLabel(pill) }}&nbsp;</label>
    </template>
  </div>
</template>
<script>
export default {
  name: 'pillbox',
  props: ['label', 'pills', 'defaultPill', 'activePillChanged'],
  data () {
    return {
      activePill: this.defaultPill || ''
    }
  },
  computed: {
    groupName () {
      if (this.label) {
        return this.label.replaceAll(' ', '').toLowerCase()
      }
      return 'pillbox'
    }
  },
  methods: {
    pillLabel (pill) {
      if (pill.label) {
        return pill.label
      }
      return "No Label"
    },
    pillId (pill) {
      if (pill.id) {
        return pill.id
      }
      return this.pillLabel(pill).replaceAll(' ', '').toLowerCase()
    },
    pillValue (pill) {
      if (pill.value) {
        return pill.value
      }
      return this.pillId(pill)
    },
    pillClicked (e) {
      if (e && e.target && e.target.value){
        this.activePill = e.target.value
        if (this.activePillChanged) {
          this.activePillChanged(e.target.value)
        }
      }
    }
  }
}
</script>