<template>
  <div>
    <div class="row form-group checkbox-input-row mb-0 pb-0">
      <label :class="labelClasses" :for="id" v-if="label || !hideEmptyLabel">{{ label }}</label>
      <div class="col-auto form-right-input mt-0 mb-1" style="margin-top:3px;">
        <div class="px-1" :class="{'checkbox-control-disabled': disabled, 'checkbox-control': !disabled}">
          <input
            type="checkbox"
            :id="id"
            v-model="model"
            :value="value"
            :name="id"
            :readonly="readonly"
            :disabled="disabled"
            class="form-check-input mt-2"
            @click="handleClickAction"
          />
          <label :for="id" class="ms-2 font-reduce-2" :class="{'extra-subtle': disabled}" v-if="checkboxLabel">{{ checkboxLabel }}</label>
        </div>
      </div>
    </div>
    <div>
      <label class="ms-3 font-reduce-2 subtle width-330 wrap" v-if="caption">{{ caption }}</label>
    </div>
    <div v-if="warning" class="d-flex ms-3 mt-1">
      <fa icon="warning" class="icon-regular status-caution mt-1" />
      <label class="ms-2 font-reduce-2 status-caution width-330 wrap">
        <span>{{ warning }}</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: ['label', 'id', 'modelValue', 'labelClass', 'clickAction', 'clickValue', 'hideEmptyLabel',
    'readonly', 'disabled', 'checkboxLabel', 'value', 'caption', 'warning', 'warningIcon'],
  emits: ['update:modelValue'],
  methods: {
    handleClickAction (e) {
      if (this.clickAction) {
        if (this.clickValue) {
          this.clickAction(this.clickValue, e)
        } else {
          this.clickAction(e)
        }
      }
    },
    checkInput (e) {
      if (!e.key) {
        return
      }
      if (this.dataTypeCharacters) {
        let v = e.key
        if (this.dataType === 'slug' || this.dataType === 'email') {
          v = v.toLowerCase()
        } else if (this.dataType === 'hex') {
          v = v.toUpperCase()
        }
        if (!this.dataTypeCharacters.includes(v)) {
          e.preventDefault()
        }
      }
    }
  },
  computed: {
    model: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    dataTypeCharacters () {
      if (!this.dataType) {
        return ''
      }
      if (this.dataType === 'slug') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-'
      }
      if (this.dataType === 'description') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 -\'.'
      }
      if (this.dataType === 'word') {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-\'.'
      }
      if (this.dataType === 'version') {
        return 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789._-'
      }
      if (this.dataType === 'phone') {
        return '0123456789-'
      }
      if (this.dataType === 'integer') {
        return '0123456789'
      }
      if (this.dataType === 'decimal') {
        return '0123456789.'
      }
      if (this.dataType === 'hex') {
        return '0123456789ABCDEF'
      }
      if (this.dataType === 'email') {
        return 'abcdefghijklmnopqrstuvwxyz0123456789-._@'
      }
      return ''
    },
    inputClasses () {
      let out = 'form-input text-input'
      if (this.inputClass) {
        if (typeof this.inputClass === 'object') {
          for (const k in this.inputClass) {
            if (this.inputClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.inputClass}`
        }
      }
      return out
    },
    labelClasses () {
      let out = 'col-form-label form-left-header pt-0'
      if (this.labelClass) {
        if (typeof this.labelClass === 'object') {
          for (const k in this.labelClass) {
            if (this.labelClass[k]) {
              out += ` ${k}`
            }
          }
        } else {
          out += ` ${this.labelClass}`
        }
      }
      if (!out.includes(' width-')) {
        out += ' width-115'
      }
      return out
    }
  }
}
</script>
