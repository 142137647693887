<template>
  <DecoderWidget ref="decoderWidget" />
  <ModalQRWidget ref="qrWidget" />
  <div class="container justify-content-center big-menu-list" style="width: 250px">
    <h4>Tools</h4>
    <div class="pt-3 info-pane text-center">
      <ul class="info-pane-list">
        <li>
          <a class="btn mt-2 mb-4" style="width:200px" @click="showPayloadDecoderWidget()">Payload Decoder</a>
        </li>
        <li>
          <a class="btn mb-4" style="width:200px" @click="showQRWidget()">QR Code Generator</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DecoderWidget from '@/components/tools/DecoderWidget.vue'
import ModalQRWidget from '@/components/tools/ModalQRWidget.vue'

export default {
  name: 'tools-landing',
  components: { DecoderWidget, ModalQRWidget },
  methods: {
    showPayloadDecoderWidget () {
      this.$refs.decoderWidget.show()
    },
    showQRWidget () {
      this.$refs.qrWidget.show()
    }
  }
}
</script>

<style>
</style>
